.chatbox-header-top-bar {
  position: sticky;
  top: 0;
  background-color: #eeeeee;
}

.chatbox-header-user-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
}

.chatbox-header-user-avatar {
  width: 63px;
  height: 63px;
  border-radius: 100%;
  background-color: #8babd8;
}

.chatbox-header-user-name {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin: 10px 20px;
}
