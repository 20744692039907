body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

:root {
  --color-primary: #8dd3bb;
  --color-secondary: #42a7c3;
  --color-accent: #112211;
  --color-background: #ffffff;
}

.icon {
  fill: var(--color-primary);
  transition: fill 0.3s ease;
}

.icon:hover {
  fill: var(--color-hover);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
html {
  font-size: 1vw;
  max-width: 2500px;
  margin: 0 auto;
}

@media (min-width: 2000px) {
  html {
    font-size: 20px;
  }
}

